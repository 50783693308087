import { Taxonomy, ContentDelivery } from '@episerver/spa-core';

export function filterLinkIdsFromLinkListProperty(
    links: Taxonomy.Property.LinkListProperty,
    tempMenuIds: string[][],
    subMenuName: string
) {
    if (links != null && links?.value?.length > 0) {
        links.value.forEach((link) => {
            if (link?.contentLink?.guidValue) tempMenuIds.push([link.contentLink.guidValue, subMenuName]);
        });
    }
}

export function filterLinkIdFromLinkProperty(
    link: ContentDelivery.Property<any>,
    tempMenuIds: string[][],
    subMenuName: string
) {
    if (link?.value?.contentLink?.guidValue) {
        tempMenuIds.push([link?.value?.contentLink?.guidValue, subMenuName]);
    }
}
