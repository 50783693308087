import { useEffect } from 'react';

export const useHashScroll = (hash: string, delay: number, rerenderArgs: any[]) => {
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            scrollToHash(hash);
        }, delay);

        return () => {
            clearTimeout(timeoutId);
        };
    }, rerenderArgs);
};

export const scrollToHash = (hash: string) => {
    if (hash) {
        const hashLinkEl = document.getElementById(hash?.replace('#', ''));

        if (hashLinkEl) {
            hashLinkEl.scrollIntoView({ behavior: 'smooth' });
        }
    }
};
