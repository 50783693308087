import LayoutSettings from 'app/Models/Content/LayoutSettingsData';

export function createLayoutSettingForBannerPayload(layoutSettings?: LayoutSettings) {
    if (!layoutSettings) {
        return;
    }

    const bannerData = layoutSettings.banner;
    if (!bannerData) {
        return;
    }

    const enoughInfoToDisplay =
        bannerData.altText.value !== '' &&
        bannerData.heading.value !== '' &&
        bannerData.mainBody.value !== null &&
        bannerData.image.value !== undefined;
    return {
        altText: bannerData.altText.value,
        heading: bannerData.heading.value,
        mainBody: bannerData.mainBody.value,
        image: bannerData.image.value,
        enoughInfoToDisplay,
    };
}
