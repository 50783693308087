import { BannerModel } from 'Components/Shared/BreadCrumbAndBanner/BannerModel';
import { FeedbackModel } from '../Components/Shared/Feedback/FeedbackModel';

const initialState = {
    isMenuOpen: false,
    text: <any>{},
    menuIds: <any>[],
    menuWithFocus: '',
    textNO: <any>null,
    textSE: <any>null,
    textEN: <any>null,
    hyphensMinWordLength: 12,
    layoutSettingModel: <FeedbackModel>{},
    banner: <BannerModel>{},
    useLanguageSelector: true,
};

// Use the initialState as a default value
export default function appReducer(state = initialState, action: any) {
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
        // Do something here based on the different types of actions

        case 'isMenuOpen':
            return {
                ...state,
                isMenuOpen: action.payload,
            };
        case 'textNO':
            return {
                ...state,
                textNO: action.payload,
            };
        case 'textSE':
            return {
                ...state,
                textSE: action.payload,
            };
        case 'textEN':
            return {
                ...state,
                textEN: action.payload,
            };
        case 'hyphensMinWordLength':
            return {
                ...state,
                hyphensMinWordLength: action.payload,
            };
        case 'menuIds':
            return {
                ...state,
                menuIds: action.payload,
            };
        case 'menuWithFocus':
            return {
                ...state,
                menuWithFocus: action.payload,
            };
        case 'layoutSettingModel':
            return {
                ...state,
                layoutSettingModel: action.payload,
            };
        case 'banner':
            return {
                ...state,
                banner: action.payload,
            };
        case 'useLanguageSelector':
            return {
                ...state,
                useLanguageSelector: action.payload,
            };
        default:
            // If this reducer doesn't recognize the action type, or doesn't
            // care about this specific action, return the existing state unchanged
            return state;
    }
}
