import store from '../store';
import {
    norwegian_language_tag_short,
    samegiella_language_tag_short,
    english_language_tag_short,
} from '../Models/Constants/LanguageTypes';

export const Beramming = 'Beramming';
export const Feedback = 'Feedback';
export const FindCourt = 'FindCourt';
export const Footer = 'Footer';
export const General = 'General';
export const Header = 'Header';
export const Judicial = 'Judicial';
export const OldDomstol = 'OldDomstol';
export const Search = 'Search';
export const SingleCourt = 'SingleCourt';
export const News = 'News';
export const TU = 'TU';

function getData(json: any, keys: string[]): string {
    try {
        if (keys != null && keys.length > 0 && json != null) {
            let temp = json;
            keys.forEach((key) => {
                if (temp.hasOwnProperty(key)) {
                    temp = temp[key];
                } else {
                    if (!temp) return '';
                }
            });

            // if typeof temp not equal to string, it should not return its value
            return typeof temp === 'string' ? temp : '';
        }
        return '';
    } catch (error) {
        console.log(error);
        return '';
    }
}

export function getLocalizationText(language: string, keys: string[]): string {
    if (language == null || language == '' || keys == null || keys.length == 0) {
        return '';
    }

    if (language == norwegian_language_tag_short) {
        const langData = store.getState()?.textNO;
        return getData(langData, keys);
    }

    if (language == samegiella_language_tag_short) {
        const langData = store.getState()?.textSE;
        return getData(langData, keys);
    }

    if (language == english_language_tag_short) {
        const langData = store.getState()?.textEN;
        return getData(langData, keys);
    }

    const langData = store.getState()?.textNO;
    return getData(langData, keys);
}

// {getLocalizationText(props.data.language.name, ['SingleCourt', 'LinkedCases'])}
// import { getLocalizationText } from '../../../utils/getLocalizationText';
